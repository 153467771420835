import React, { useEffect } from 'react';
import { Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import LoadingButton from 'components/common/LoadingButton';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFControl, RHFSelect } from 'components/common/form';
import Flex from 'components/common/Flex';

const schema = Yup.object().shape({
  school: Yup.string().required('Required Field'),
  comment: Yup.string().required('Required Field'),
  evaluation: Yup.string().required('Required Field')
});
function AddReportModal({
  show,
  onHide,
  title,
  loading,
  onSubmit,
  initValues,
  schoolOptions
}) {
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      school: '',
      comment: '',
      evaluation: ''
    },
    resolver: yupResolver(schema)
  });

  const { handleSubmit, reset } = methods;
  useEffect(() => {
    if (initValues) {
      reset({
        school: initValues.school_id,
        comment: initValues.comment,
        evaluation: initValues.evaluation
      });
    }
  }, [initValues]);

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton className="modal-header">
          <Modal.Title className="modal-header-title">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-3 ps-3 pe-3">
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(onSubmit)}
            loading={loading}
          >
            <Flex gap={3} direction="column" className="mt-3">
              <Row>
                <RHFSelect
                  label={'school'}
                  name={'school'}
                  options={schoolOptions}
                  required
                />
                <RHFControl
                  label={'Comment'}
                  name="comment"
                  placeholder="comment"
                  type="text"
                  as="textarea"
                  rows={5}
                  required={true}
                />
                <RHFControl
                  label={'Evalution'}
                  type={'number'}
                  name="evaluation"
                  required
                />
              </Row>
              <LoadingButton
                style={{ width: '100%', color: 'white' }}
                variant="primary"
                loading={loading}
                disabled={loading}
                type={'submit'}
              >
                {initValues ? 'Edit' : 'Save'}
              </LoadingButton>
            </Flex>
          </FormProvider>
        </Modal.Body>
      </Modal>
    </>
  );
}
AddReportModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  title: PropTypes.string,
  loading: PropTypes.bool,
  confirmText: PropTypes.string,
  onSubmit: PropTypes.func,
  schoolOptions: PropTypes.array,
  initValues: PropTypes.object
};
export default AddReportModal;
