import React from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileBeneficiaryInfo, {
  OrphanDonationCard
} from './beneficiary-profile-components/ProfileBeneficiaryInfo';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import classNames from 'classnames';
import IconButton from 'components/common/IconButton';
import paths from 'routes/paths';
import { useBeneficiaryContext } from 'context/beneficiaryContext';
import { parseRouteParams } from 'helpers/utils';
import { PDFDownloadLink } from '@react-pdf/renderer';

const BeneficiaryProfile = () => {
  const navigate = useNavigate();
  const { beneficiary } = useBeneficiaryContext();

  const profileDetails = [
    {
      id: 1,
      title: 'Full Name',
      value: beneficiary.first_name + ' ' + beneficiary.last_name,
      valueClassName: 'text-primary',
      link: '/'
    },
    { id: 2, title: 'Registration Date', value: beneficiary.created_at },
    { id: 3, title: 'DOB', value: beneficiary.DOB },
    { id: 4, title: 'Address', value: 'N/A' },
    { id: 5, title: 'City', value: beneficiary.city },
    {
      id: 6,
      title: 'Language',
      value: beneficiary.lnaguage
    },
    { id: 7, title: 'Country', value: beneficiary.country },
    {
      id: 8,
      title: 'Medical Condition',
      value: beneficiary.health_notes
    },
    {
      id: 8,
      title: 'Additional Info',
      value: 'N/A'
    },
    {
      id: 8,
      title: 'Career aspiration',
      value: beneficiary.inspiration
    }
  ];

  return (
    <>
      <Card className="mb-3 p-2">
        <FalconCardHeader
          title={
            <IconButton
              onClick={() => navigate(paths.beneficiaries)}
              icon={'arrow-left'}
              iconClassName="me-2"
              variant="link"
              className="px-0 fs--1 fs-md-0"
            >
              Back To Beneficiary List
            </IconButton>
          }
          light={false}
          titleTag="div"
          className="pb-1"
          menuClassName="align-items-start"
          endEl={
            <div>
              <Button
                variant="outline-primary"
                className="me-2"
                onClick={() =>
                  navigate(
                    parseRouteParams(paths.editBeneficiary, {
                      id: beneficiary.id
                    })
                  )
                }
              >
                Edit
              </Button>
              {beneficiary && (
                <PDFDownloadLink
                  document={<OrphanDonationCard beneficiary={beneficiary} />}
                  fileName={
                    beneficiary.first_name +
                    '-' +
                    beneficiary.last_name +
                    '.pdf'
                  }
                >
                  {({ loading }) => {
                    return (
                      <Button>
                        {loading ? 'Loading' : 'Print Orphan Card'}
                      </Button>
                    );
                  }}
                </PDFDownloadLink>
              )}
            </div>
          }
        />

        <FalconCardHeader
          title={<h4 className="fw-bold">Profile Overview</h4>}
          light={false}
          titleTag="div"
          className="pb-1"
          menuClassName="align-items-start"
        />
        <Card.Body>
          <Row>
            <Col md={8}>
              <Table size="sm" borderless className="font-sans-serif mb-0">
                <tbody>
                  {profileDetails?.map(detail => (
                    <tr key={detail.id}>
                      <td className="text-start ps-0">{detail.title}</td>
                      <td
                        className={classNames(
                          'text-start',
                          detail.valueClassName
                        )}
                      >
                        {detail.link ? (
                          <a href={detail.link}>{detail.value}</a>
                        ) : (
                          detail.value
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <ProfileBeneficiaryInfo />
    </>
  );
};

export default BeneficiaryProfile;
